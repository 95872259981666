<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- 搜索按钮 -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
                  class="mr-1"
              >
                <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <!-- 高级搜索 -->
        <b-card v-if="isShowCard">
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition">
            <b-row>
              <!--货权转移编号-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="转移编号"
                    label-for="force_no"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="force_no"
                      size="sm"
                      v-model="condition.force_no"
                  />
                </b-form-group>
              </b-col>
              <!--转移前主体-->
              <b-col md="3">
                <modal-select
                    label="转移前主体"
                    type="input"
                    v-on:change="fromChildren($event,['before_company_name','before_company_id'],condition)"
                    :params="['before_company_name','before_company_id']"
                    modalName="签约主体"
                    placeholder="点击选择转移前主体"
                    v-model="condition.before_company_name"
                >
                </modal-select>
              </b-col>
              <!--转移后主体-->
              <b-col md="3">
                <modal-select
                    label="转移后主体"
                    type="input"
                    v-on:change="fromChildren($event,['after_company_name','after_company_id'],condition)"
                    :params="['after_company_name','after_company_id']"
                    modalName="签约主体"
                    placeholder="点击选择转移后主体"
                    v-model="condition.after_company_name"
                >
                </modal-select>
              </b-col>
              <!--仓库名称-->
              <b-col md="3">
                <modal-select
                    label="仓库名称"
                    type="input"
                    v-on:change="fromChildren($event,['warehouse_name','warehouse_id'],condition)"
                    :params="['warehouse_name','warehouse_id']"
                    modalName="仓库"
                    placeholder="点击选择仓库"
                    v-model="condition.warehouse_name"
                >
                </modal-select>
              </b-col>
              <!--创建日期-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="创建日期"
                    label-for="create_time"
                    label-size="sm"
                >
                  <flat-pickr
                      v-model="condition.create_time"
                      class="form-control"
                      :config="rangeConfig"
                  />
                </b-form-group>
              </b-col>
              <!--关联编号-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="关联编号"
                    label-for="order_no"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="order_no"
                      size="sm"
                      v-model="condition.order_no"
                  />
                </b-form-group>
              </b-col>

              <!--查询、重置按钮-->
              <b-col
                  cols="12"
                  md="12"
                  class=" demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <!--details-->
        <template #row-details="row">
          <cargo-force-item-list :force_id="row.item.force_id"></cargo-force-item-list>
        </template>

        <!-- Columns -->
        <template #cell(force_id)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                icon="MinusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==true"
            />
            <feather-icon
                icon="PlusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          #{{ data.item.force_id }}
        </template>

        <!-- 添加时间 -->
        <template #cell(create_time)="data">
          {{toDate(data.item.create_time)}}
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import cargoforceUseList from './cargoforceUseList'
import cargoforceStore from './cargoforceStore'
import CargoForceItemList from "@/views/apps/cargoforceitem/CargoForceItemList";
import Ripple from "vue-ripple-directive";
import ModalSelect from "@/views/components/modal/ModalSelect";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    CargoForceItemList,
    ModalSelect,
  },
  data() {
    return {
      isShowCard: false,
      rangeConfig: {
        mode: 'range',
        maxDate: 'today',
      },
    }
  },
  directives: {
    Ripple,
  },
  setup() {
    // Register module
    if (!store.hasModule('cargoforce')) store.registerModule('cargoforce', cargoforceStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('cargoforce')) store.unregisterModule('cargoforce')
    })

    const advanced_search = function () {
      this.isShowCard = !this.isShowCard
    }

    const searchByCondition = function () {
      refetchData()
    }

    const resetCondition = function () {
      this.condition = {}
      refetchData();
    }

    const fromChildren = function (params, modal, item) {
      for (let i = 0; i < modal.length; i++) {
        item[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,
      // UI
    } = cargoforceUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      advanced_search,
      searchByCondition,
      resetCondition,
      condition,
      fromChildren,
      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
     toTime,
     toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
